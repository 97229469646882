



























import {Component, Vue} from 'vue-property-decorator';
import CompanyService from '@/services/CompanyService';
import {NotificationTypes, notify, resolveError} from '@/utils/notifications';
import {i18n} from '@/plugins/i18n';

@Component({
  name: 'OAuth',
})

export default class OAuth extends Vue {

  private nextStep: any = 1;

  private apiType = '';
  private loading: boolean = false;

  async navigate() {
    if (this.nextStep === 4){
      await this.$router.push({name: 'CompanyConfig.vue'});
    } else {
      let step;
      switch (this.apiType) {
        case 'spapi':
          step = 2;
          break;
        case 'marketing':
          step = 3;
          break;
      }
      // @ts-ignore
      await this.$router.push({name: 'Configuration', query: {step: step}});
    }
  }

  mounted() {
    this.getActualStep();
    this.apiType = this.$route.params.apiType;

    switch (this.apiType) {
      case 'spapi':
        this.performSPAPI()
        break;
      case 'marketing':
        this.performMarketing();
        break;
    }
  }

  async getActualStep() {
    try {
      this.nextStep = await CompanyService.getActualStep(true);
    } catch (e) {
      resolveError(e, 'fetch_countries');
    }
  }

  async performMarketing() {
    if (!!this.QueryParams.code && !!this.QueryParams.state) {
      const code = this.QueryParams.code;
      const id = this.QueryParams.state;
      // @ts-ignore
      await this.$router.replace({'query': null});
      await this.exchangeMarketing(id, code);
    }
  }

  async performSPAPI() {
    if (!!this.QueryParams.selling_partner_id && !!this.QueryParams.spapi_oauth_code) {
      const api = {
        sellingPartnerId: this.QueryParams.selling_partner_id,
        mwsAuthToken: this.QueryParams.mws_auth_token,
        spApiOauthCode: this.QueryParams.spapi_oauth_code,
      }

      // @ts-ignore
      await this.$router.replace({'query': null});
      await this.exchangeSPAPI(api);
    }
  }

  async exchangeMarketing(id: any, code: any) {
    try {
      this.loading = true;
      await CompanyService.exchangeMarketing(code);
      notify(
          NotificationTypes.success,
          i18n.t('SYSTEM_CONFIGURATION_AUTHORIZED'),
      );
      setTimeout(() => this.navigate(), 2000);
    } catch (e) {
      resolveError(e, 'fetch_data');
    } finally {
      this.loading = false;
    }
  }

  async exchangeSPAPI(api: any) {
    try {
      this.loading = true;
      await CompanyService.exchangeSPAPI(api);
      notify(
          NotificationTypes.success,
          i18n.t('SYSTEM_CONFIGURATION_AUTHORIZED'),
      );
      setTimeout(() => this.navigate(), 2000);
    } catch (e) {
      resolveError(e, 'fetch_data');
    } finally {
      this.loading = false;
    }
  }

  get QueryParams() {
    return this.$route.query;
  }

}

